import React from 'react';
import styled from 'styled-components';
import './App.css';

const SakuraContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const Sakura = styled.div`
	margin: 5px;
	animation: App-logo-spin infinite 20s linear;
	text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
		-1px 1px 0 #000, 1px 1px 0 #000;
`;

const Sakuras = () => (
	<SakuraContainer>
		<Sakura>{'🌸'}</Sakura>
		<Sakura>{'🌸'}</Sakura>
		<Sakura>{'🌸'}</Sakura>
	</SakuraContainer>
);

const StyledLink = styled.a`
	color: white;
	text-decoration: none;
	text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
		-1px 1px 0 #000, 1px 1px 0 #000;
`;

const Link = ({ children, ...props }) => (
	<StyledLink target="_blank" rel="noopener noreferrer" {...props}>
		{children}
	</StyledLink>
);

const Subtitle = styled.div`
	color: white;
	font-family: Helvetica Neue, sans-serif;
	-webkit-text-stroke: initial;
	text-shadow: none;
	text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
		-1px 1px 0 #000, 1px 1px 0 #000;
	margin-top: -20px;
`;

function App() {
	return (
		<div className="App">
			<header className="App-header">
				<Sakuras />
				<p>
					<Link href="https://twitch.tv/kodosenpai">KODO SENPAI</Link>
				</p>
				<Subtitle>コード センパイ</Subtitle>
				<Sakuras />
			</header>
		</div>
	);
}

export default App;
